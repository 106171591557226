import React from 'react';
import PlayWhite from '@/assets/images/home/hero/play_w.svg';

type Props = {
    type: CardType
}

const CardButton = ({ type }: Props) => {
    return (
        <div
            className={`
                w-full min-w-[calc(100%+6px)] select-none cursor-default flex justify-center items-center gap-x-1 py-[10px] px-[10px] transition duration-300 rounded-bl-[8px]
                md:w-fit md:min-w-0 md:gap-x-2 md:px-[16px] md:py-2 md:rounded-bl-none md:rounded-tl-3xl md:whitespace-nowrap
                lg:gap-x-3 lg:px-[20px]
                ${
                    type === 'navi' ?
                    'bg-[#06B6D4] group-hover:bg-[#0891B2] group-hover:scale-105' :
                    'bg-[#3866AB] group-hover:bg-[#305893] group-hover:scale-105'
                }
            `}>
            <div
                className='
                    w-[14px]
                    md:w-[16px]
                    lg:w-[18px]
                '>
                <img
                    src={PlayWhite}
                    alt="play"
                    sizes='100%'
                />
            </div>
            <p
                className={`
                    text-white text-[16px] font-bold transition-colors duration-300
                    md:text-[22px]
                    lg:text-[28px]
                `}>
                サービスサイトを見る
            </p>
        </div>
    );
}

export default CardButton;